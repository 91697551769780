import { useEffect } from 'react';

const GoogleAnalytics = () => {
    useEffect(() => {
        // Add the Google Analytics script dynamically
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-V9J543YLPV';
        document.head.appendChild(script);

        // Initialize Google Analytics when the script is loaded
        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-V9J543YLPV');
        };

        return () => {
            // Clean up script if the component is unmounted
            document.head.removeChild(script);
        };
    }, []);

    return null; // This component doesn't render anything
};

export default GoogleAnalytics;
