import React from "react";
import Forms from "../components/Forms";
import Background from "../components/Background";
import NavBar from "../components/NavBar";
import { useWindowSize } from "react-use";
import { useNavigate } from "react-router-dom";
import Form from "../components/Form";

const Enquiry = ({ flag, setFlag }) => {

  const { width } = useWindowSize();
  const navigate = useNavigate();

  return (
    <main className="wrapper">
      <div id="home" className="homeWrapper">
        <section>
          <Background />
        </section>
        <section>
          <NavBar />
        </section>
        {!flag && <section>
          {width < 1310 ?
            <div id="form" className="heroRight enquiryFormMob">
              <Forms setFlag={setFlag} attribute={'enquiry'} />
            </div> :
            <div className="enquiryFormDesktop">
              <Form setFlag={setFlag} attribute={'enquiry'} />
            </div>}
        </section>}
        {flag && <div class="homeContainer">
          {width <= 420 ? <h2>Thanks for your enquiry!</h2> : <h1>Thanks for your enquiry!</h1>}
          <p>You can also reach us at +919084340987</p>
          <p onClick={() => navigate("/")} className="homeButton">Take Me to Home</p>
        </div>}
      </div>
      <footer className="enquiryFooter">
        Copyright © 2024 Piewebservices. All Rights Reserved.
      </footer>
    </main>
  );
};

export default Enquiry;
