import React from "react";
import {
  technologyEightImg,
  technologyFiveImg,
  technologyFourImg,
  technologyNineImg,
  technologyOneImg,
  technologySevenImg,
  technologySixImg,
  technologyTenImg,
  technologyThreeImg,
  technologyTwoImg,
} from "../assets/images";

const Technologies = () => {
  return (
    <div className="technologiesWrapper">
      <h2 className="heading">Technologies we have worked with</h2>
      <div className="technologies">
        <div>
          <img loading="lazy" src={technologyOneImg} alt="technologyOneImg" />
        </div>
        <div>
          <img loading="lazy" src={technologyTwoImg} alt="technologyTwoImg" />
        </div>
        <div>
          <img loading="lazy" src={technologyThreeImg} alt="technologyThreeImg" />
        </div>
        <div>
          <img loading="lazy" src={technologyFourImg} alt="technologyFourImg" />
        </div>
        <div>
          <img loading="lazy" src={technologyFiveImg} alt="technologyFiveImg" />
        </div>
        <div>
          <img loading="lazy" src={technologySixImg} alt="technologySixImg" />
        </div>
        <div>
          <img loading="lazy" src={technologySevenImg} alt="technologySevenImg" />
        </div>
        <div>
          <img loading="lazy" src={technologyEightImg} alt="technologyEightImg" />
        </div>
        <div>
          <img loading="lazy" src={technologyNineImg} alt="technologyNineImg" />
        </div>
        <div>
          <img loading="lazy" src={technologyTenImg} alt="technologyTenImg" />
        </div>
      </div>
    </div>
  );
};

export default Technologies;
