import React from 'react'

const Process = () => {
    return (
        <div className='process-body'>
            <div className="process-container">
                <h1 className="process-title">Process We Follow To Deliver Your Website</h1>

                <div className="process-steps">
                    <div className="process-step">
                        <div className="arrow-box">
                            <p>Website Blueprint</p>
                        </div>
                    </div>
                    <div className="process-step">
                        <div className="arrow-box">
                            <p>Design Creation</p>
                        </div>
                    </div>
                    <div className="process-step">
                        <div className="arrow-box">
                            <p>Feedbacks</p>
                        </div>
                    </div>
                    <div className="process-step">
                        <div className="arrow-box">
                            <p>Development</p>
                        </div>
                    </div>
                    <div className="process-step">
                        <div className="arrow-box">
                            <p>Launch</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Process
