import React, { useState } from "react";
import { logoImage } from "../assets/images";
import { closeIcon, menuIcon } from "../assets/icons";
import { useLocation } from "react-router-dom";

const NavBar = ({ scrollToSection }) => {

  // const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const ToggleSidebar = (section) => {
    if (isOpen) {
      setIsOpen(false)
      scrollToSection(section)
      // if (location.pathname === '/' && section === 'form') {
      //   scrollToSection("form")
      // } else if (section === 'form') {
      //   navigate(`/enquiry`)
      // } else if (section === 'home') {
      //   navigate(`/`)
      // } else {
      //   navigate(`/${section}`)
      // }
    } else {
      setIsOpen(true)
    }
  };

  // const handleQuote = () => {
  //   if (location.pathname === '/') {
  //     scrollToSection("form")
  //   } else {
  //     navigate('/enquiry')
  //   }
  // }

  return (
    <div className="navWrapper">
      <div className="navBarLogo">
        <img loading="lazy" src={logoImage} alt="logoImage" />
      </div>
      <div className="navBar">
        <div className="navBarBig">
          <div></div>
          <div className="navBarLink">
            <span onClick={() => scrollToSection('home')} className={location.pathname === '/' ? "active" : ''}>Home</span>
            <span onClick={() => scrollToSection('about')} className={location.pathname === '/about' ? "active" : ''}>About Us</span>
            <span onClick={() => scrollToSection('services')} className={location.pathname === '/services' ? "active" : ''}>Our Services</span>
            <span onClick={() => scrollToSection('form')} className={location.pathname === '/enquiry' ? "active" : ''}>Contact Us</span>
          </div>
          <div onClick={() => scrollToSection('form')} className="navBarQuote btn">
            <span>Quote</span>
          </div>
        </div>
        <div className="navBarMobileIcon">
          <img loading="lazy"
            className="navBarMenuIcon"
            src={menuIcon}
            onClick={ToggleSidebar}
            width={30}
            height={30}
            alt="menuIcon"
          />
        </div>
      </div>
      <div className="navBarMobile">
        <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
          <div className="navBarLink">
            <span onClick={() => ToggleSidebar('home')} className={location.pathname === '/' ? "active" : ''}>Home</span>
            <span onClick={() => ToggleSidebar('about')} className={location.pathname === '/about' ? "active" : ''}>About Us</span>
            <span onClick={() => ToggleSidebar('services')} className={location.pathname === '/services' ? "active" : ''}>Our Services</span>
            <span onClick={() => ToggleSidebar('form')} className={location.pathname === '/enquiry' ? "active" : ''}>Contact Us</span>
            <img loading="lazy"
              onClick={() => ToggleSidebar(null)}
              src={closeIcon}
              alt="closeIcon"
              width={24}
              height={24}
            />
          </div>
          <div onClick={() => ToggleSidebar('form')} className="navBarQuote btn">
            <span>Quote</span>
          </div>
        </div>
        <div
          className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
          onClick={ToggleSidebar}
        ></div>
      </div>
    </div>
  );
};

export default NavBar;